import styles from './index.module.scss'
import ICON1 from '@/assets/imgs/main10_icon1.png'
import ICON2 from '@/assets/imgs/main10_icon2.png'
import ICON3 from '@/assets/imgs/main10_icon4.png'

import ICON4 from '@/assets/imgs/main10_icon3.png'
import ICON5 from '@/assets/imgs/main10_icon5.png'

const ContentTen = () => {
  return (
    <div className={styles.content}>
      <div className={styles.title}>—— 五大学习模块 ——</div>
      <div className={styles.box}>
        <div className={styles.panel}>
          <div className={styles.panelLeft}>
            <div className={styles.row}>
              <div className={styles.topCard}>
                <div className={styles.topCardHeader}>
                  <div className={styles.text}>
                    婚恋的时代背景
                    <br />
                    与婚恋行业
                    <br />
                    的发展现状
                  </div>
                  <img className={styles.icon} src={ICON1} />
                </div>
                <div className={styles.topCardBody}>
                  <div className={styles.text}>
                    ·了解中国婚恋市场的变迁与发展
                    <br />
                    ·习得婚姻幸福家庭美满的能力
                    <br />
                    ·构建终身受益的亲密关系
                  </div>
                </div>
              </div>
              <div className={styles.topCard}>
                <div className={styles.topCardHeader}>
                  <div className={styles.text}>
                    从恋爱到
                    <br />
                    婚姻
                  </div>
                  <img className={styles.icon} src={ICON2} />
                </div>
                <div className={styles.topCardBody}>
                  <div className={styles.text}>
                    ·了解恋爱与婚姻的共性问题
                    <br />
                    ·学习如何经营情感
                    <br />
                    ·打造持续吸引力
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.bottomCard}>
                <div className={styles.bottomCardHeader}>
                  <div className={styles.text}>
                    婚恋中的情感问题
                    <br />
                    分析与疗愈教练技术在情感
                  </div>
                  <img className={styles.icon} src={ICON3} />
                </div>
                <div className={styles.bottomCardBody}>
                  <div className={styles.text}>
                    ·了解经营情感关系需要具备的能力
                    <br />
                    ·学习情感问题分析的相关理论于工具
                    <br />
                    ·学习如何疗愈与修复情感问题于情感关系
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.panelRight}>
            <div className={styles.topCard}>
              <div className={styles.topCardHeader}>
                <div className={styles.text}>
                  婚恋
                  <br />
                  与心理学
                </div>
                <img className={styles.icon} src={ICON4} />
              </div>
              <div className={styles.topCardBody}>
                <div className={styles.text}>
                  ·学习心理学的专业内容
                  <br />
                  ·并针对性的应用在婚姻情感生活中
                </div>
              </div>
            </div>
            <div className={styles.bottomCard}>
              <div className={styles.bottomCardHeader}>
                <div className={styles.text}>
                  中国现代婚姻制度
                  <br />
                  变迁及现状
                </div>
                <img className={styles.icon} src={ICON5} />
              </div>
              <div className={styles.bottomCardBody}>
                <div className={styles.text}>
                  ·学习民法典婚姻家庭编
                  <br />
                  ·了解婚姻生活中的各项人身保护权益
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContentTen
