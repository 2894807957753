import classNames from 'classnames'
import React, { MouseEvent, TouchEvent, useEffect, useRef, useState } from 'react'
import TransitionGroup from 'react-transition-group/TransitionGroup'

import styles from './index.module.scss'
import Ripple from './Ripple'

export type IRippleWrapperProps = {
  className?: string
  color?: string
  center?: boolean
  children?: React.ReactNode
  timeout?: {
    enter: number
    exit: number
  }
}

function RippleWrapper(props: IRippleWrapperProps) {
  const {
    className,
    center,
    timeout: _pTimeout = { enter: 500, exit: 500 },
    color,
    children,
    ...rest
  } = props
  const [ripples, setRipples] = useState<JSX.Element[]>([])
  const [nextKey, setNextKey] = useState(0)
  const startTimeout = useRef<NodeJS.Timeout | null>(null)
  const startWrapper = useRef<Function | null>(null)
  const ignoringMousedown = useRef(false)

  const handleMouseDown = (e: MouseEvent) => {
    start(e)
  }

  const handleMouseUp = (e: MouseEvent) => {
    stop(e)
  }

  const handleMouseLeave = (e: MouseEvent) => {
    stop(e)
  }

  const handleTouchStart = (e: TouchEvent) => {
    start(e)
  }

  const handleTouchEnd = (e: TouchEvent) => {
    stop(e)
  }

  const handleTouchMove = (e: TouchEvent) => {
    stop(e)
  }

  useEffect(() => {
    return () => {
      startTimeout.current && clearTimeout(startTimeout.current)
    }
  }, [])

  function start(e: any) {
    if (e.type === 'mousedown' && ignoringMousedown.current) {
      ignoringMousedown.current = false
      return
    }
    if (e.type === 'touchstart') {
      ignoringMousedown.current = true
    }
    const element = e.target as HTMLElement
    const rect = element
      ? element.getBoundingClientRect()
      : {
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          width: 0,
          height: 0
        }

    let rippleX: number
    let rippleY: number
    let rippleSize: number
    if (center || (e!.clientX === 0 && e.clientY === 0) || (!e.clientX && !e.touches)) {
      rippleX = Math.round(rect.width / 2)
      rippleY = Math.round(rect.height / 2)
    } else {
      const clientX = e.clientX ? e.clientX : e.touches[0].clientX
      const clientY = e.clientY ? e.clientY : e.touches[0].clientY
      rippleX = Math.round(clientX - rect.left)
      rippleY = Math.round(clientY - rect.top)
    }

    // calculate the size of the ripple
    if (center) {
      rippleSize = Math.sqrt((2 * rect.width ** 2 + rect.height ** 2) / 2)
    } else {
      const sizeX =
        Math.max(Math.abs((element ? element.clientWidth : 0) - rippleX), rippleX) * 2 + 2
      const sizeY =
        Math.max(Math.abs((element ? element.clientHeight : 0) - rippleY), rippleY) * 2 + 2
      rippleSize = Math.sqrt(sizeX ** 2 + sizeY ** 2)
    }
    if (e.touches) {
      startWrapper.current = () => {
        createRipple({ rippleX, rippleY, rippleSize, timeout: _pTimeout })
      }
      startTimeout.current = setTimeout(() => {
        startWrapper?.current?.()
        startWrapper.current = null
      }, 80)
    } else {
      createRipple({ rippleX, rippleY, rippleSize, timeout: _pTimeout })
    }
  }
  function createRipple(params: {
    rippleX: number
    rippleY: number
    rippleSize: number
    timeout: { enter: number; exit: number }
  }) {
    const { rippleX, rippleY, rippleSize, timeout } = params
    const tRipples = [
      ...ripples,
      <Ripple
        timeout={timeout}
        color={color!}
        key={nextKey}
        rippleX={rippleX}
        rippleY={rippleY}
        rippleSize={rippleSize}
      />
    ]

    setRipples(tRipples)
    setNextKey(nextKey + 1)
  }
  function stop(e: any) {
    startTimeout.current && clearTimeout(startTimeout.current)
    if (e.type === 'touchend' && startWrapper.current) {
      e.persist()
      startWrapper.current()
      startWrapper.current = null
      startTimeout.current = setTimeout(() => {
        stop(e)
      }, 0)
      return
    }
    startWrapper.current = null

    setRipples(ripples.slice(1))
  }

  return (
    <>
      <span
        className={classNames(styles['rtr-root'], className)}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseLeave}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        onTouchMove={handleTouchMove}
        {...rest}
      >
        <TransitionGroup enter exit>
          {ripples}
        </TransitionGroup>
      </span>
      {children}
    </>
  )
}

RippleWrapper.defaultProps = {
  center: false,
  color: 'currentColor',
  timeout: {
    enter: 500,
    exit: 500
  }
}

export default RippleWrapper
