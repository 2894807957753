import styles from './index.module.scss'

const GUIDES = [
  '报考条件',
  '报考时间',
  '报名费用',
  '考试教材',
  '报考指南',
  '考试科目',
  '考试大纲',
  '考试时间',
  '报名入口',
  '往年例题',
  '成绩查询',
  '更多>>'
]

const EXAM_GUIDES = [
  '0基础如何报考婚恋规划？',
  '婚恋规划通过率高吗？难吗？',
  '婚恋规划可以自学吗？',
  '婚恋规划如何参加考试？',
  '关于婚恋规划更多报名考试问题'
]

const COST_GUIDES = [
  '五大模块',
  '名师教学',
  '三十门课程，五十五个课时',
  '训后辅以线上学习群的定期案例分析，相互练习',
  '综合学习时长超过六十小时'
]
const CARDS = [
  { title: '考试介绍', desc: '如何考试' },
  { title: '日程安排', desc: '2024年婚恋规划考试日程' },
  { title: '报名流程', desc: '报名需要准备的资料' },
  { title: '考生服务', labels: ['报名系统', '证书查询', '成绩查询', '免考认证'] }
]

const ContentOne = () => {
  return (
    <div className={styles.content}>
      <div className={styles.title}>
        — 2024婚恋规划 <span>【报考信息查询】</span> —
      </div>

      <div className={styles.body}>
        <div className={styles.top}>
          <div className={styles.block}>
            <div className={styles.title}>{'考试指南>>'}</div>
            <div className={styles.list}>
              {GUIDES.map((item, index) => {
                return (
                  <div key={index} className={styles.listItem} data-button>
                    {item}
                  </div>
                )
              })}
            </div>
          </div>
          <div className={styles.block}>
            <div className={styles.title}>{'考试费用包含>>'}</div>
            <li>
              {COST_GUIDES.map((item, index) => {
                return (
                  <ul key={index}>
                    <div className={styles.text} data-button>
                      {item}
                    </div>
                    <div className={styles.button} data-button>
                      点击查看
                    </div>
                  </ul>
                )
              })}
            </li>
          </div>
          <div className={styles.block}>
            <div className={styles.title}>{'专业技能证书>>'}</div>
            <div className={styles.blockRight}>
              <div className={styles.tip}>智能题库系统，大数据支持</div>
              <div className={styles.title}>婚恋规划</div>
              <div className={styles.subtitle}>专业技能证书</div>
              <div className={styles.button} data-button>
                {'报名入口>>'}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.center}>
          {CARDS.map((each, index) => {
            return (
              <div key={index} className={styles.card}>
                <div className={styles.title}>{each.title}</div>
                {each.desc && (
                  <div className={styles.desc} data-button>
                    {each.desc}
                  </div>
                )}
                {each.labels && (
                  <div className={styles.labels}>
                    {each.labels.map((label, i) => {
                      return (
                        <div key={i} className={styles.label} data-button>
                          {label}
                        </div>
                      )
                    })}
                  </div>
                )}
              </div>
            )
          })}
        </div>

        <div className={styles.bottom}>
          <div className={styles.block}>
            <div className={styles.title}>{'证书介绍>>'}</div>
            <div className={styles.box1}>
              <div className={styles.tip}>行业中通常被大家尊称为”婚恋规划”</div>
              <div className={styles.box1Content}>
                <div className={styles.subtitle}>国家认可，人社局颁证</div>
                <div className={styles.desc}>
                  婚恋规划是通过专业、科学的理论与实战、落地的技能，为在恋爱、婚姻等情感生活中遇到各种问题和困惑的来访者，提供问题咨询和技能辅导的专业人员。
                </div>
              </div>
            </div>
          </div>
          <div className={styles.block}>
            <div className={styles.title}>{'考试指南>>'}</div>
            <li>
              {EXAM_GUIDES.map((item, index) => {
                return (
                  <ul key={index}>
                    <div className={styles.text}>{item}</div>
                    <div className={styles.button} data-button>
                      点击查看
                    </div>
                  </ul>
                )
              })}
            </li>
          </div>
          <div className={styles.block}>
            <div className={styles.title}>{'考试截止时间>>'}</div>
            <div className={styles.blockRight}>
              <div className={styles.tip}>2024年婚恋规划</div>
              <div className={styles.desc}>
                考试报名培训<span>截止时间</span>
              </div>
              <div className={styles.dates}>
                <div className={styles.date}>
                  <div className={styles.number}>4</div>
                  <div className={styles.symbol}>/月</div>
                </div>
                <div className={styles.date}>
                  <div className={styles.number}>1</div>
                  <div className={styles.symbol}>/日</div>
                </div>
              </div>
              <div className={styles.buttonBlock}>
                <div className={styles.button} data-button>
                  {'立即报名>>'}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContentOne
