import classNames from 'classnames'
import React, { CSSProperties, useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'
import { CSSTransition } from 'react-transition-group'

import styles from './index.module.scss'

export function useInitialized(load?: boolean) {
  const initializedRef = useRef(load)
  /** 只在初始化时调用 防止动画未结束 内容就消失了 */
  if (load) {
    initializedRef.current = true
  }
  return !!initializedRef.current
}

export type MaskProps = {
  className?: string
  style?: CSSProperties
  visible?: boolean
  onMaskClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  destroyOnClose?: boolean
  forceRender?: boolean
  disableBodyScroll?: boolean
  opacity?: number
  getContainer?: HTMLElement | (() => HTMLElement) | null
  afterClose?: () => void
  children?: React.ReactNode
}

const Mask: React.FC<MaskProps> = (props) => {
  const cls = classNames(styles.mask, props.className)
  const initialized = useInitialized(props.visible || props.forceRender)
  function handleClick(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    if (e.currentTarget === e.target) {
      props.onMaskClick?.(e)
      e.stopPropagation()
    }
  }

  useEffect(() => {
    if (props.disableBodyScroll && props.visible) {
      document.body.classList.add('overflow-hidden')
    }
    return () => {
      document.body.classList.remove('overflow-hidden')
    }
  }, [props.visible, props.disableBodyScroll])

  const node = () => (
    <CSSTransition
      in={props.visible}
      timeout={300}
      classNames={{
        enter: styles.maskEnter,
        enterActive: styles.maskEnterActive,
        exit: styles.maskExit,
        exitActive: styles.maskExitActive
      }}
      onExited={() =>
        setTimeout(() => {
          props?.afterClose?.()
        }, 0)
      }
      unmountOnExit={props.destroyOnClose}
    >
      <div
        className={cls}
        onClick={handleClick}
        style={{
          ...props.style,
          backgroundColor: `rgba(0, 0, 0, ${props.opacity ?? 0.45})`
        }}
      >
        {initialized && props.children}
      </div>
    </CSSTransition>
  )

  if (props.getContainer) {
    const container =
      typeof props.getContainer === 'function' ? props.getContainer() : props.getContainer
    return createPortal(node(), container)
  }
  return node()
}

export default Mask
