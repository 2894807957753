import styles from './index.module.scss'
import MAIN5_ICON1 from '@/assets/imgs/main5_icon1.png'
import MAIN5_ICON2 from '@/assets/imgs/main5_icon2.png'

import MAIN5_ICON3 from '@/assets/imgs/main5_icon3.png'
import MAIN5_ICON4 from '@/assets/imgs/main5_icon4.png'
import MAIN5_ICON5 from '@/assets/imgs/main5_icon5.png'

const CONTENT_FIVE = [
  { img: MAIN5_ICON1, text: '婚恋情感行业从业者，如：婚恋机构服务人员，情感类主播' },
  { img: MAIN5_ICON2, text: '提供咨询服务的专业工作者，如：心理咨询师、心理治疗师、律师等' },
  { img: MAIN5_ICON3, text: '遇到恋爱、交友、婚姻家庭、亲子教育等困惑人群' },
  {
    img: MAIN5_ICON4,
    text: '热心为身边人解决婚恋、婚姻家庭困扰的人，如：社工、调解人员、心理学爱好者'
  },
  { img: MAIN5_ICON5, text: '准备毕业后从事相关工作的在校学生' }
]

const ContentFive = () => {
  return (
    <div className={styles.content}>
      <div className={styles.title}>—— 婚恋规划适合的学习对象 ——</div>
      <div className={styles.list}>
        {CONTENT_FIVE.map((each, index) => {
          return (
            <div key={index} className={styles.listItem}>
              <img className={styles.img} src={each.img} />
              <div className={styles.text}>{each.text}</div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default ContentFive
