import classNames from 'classnames'
import React, { ReactElement, ReactNode, useState, useEffect } from 'react'

import { Mask } from '../mask'
import styles from './index.module.scss'
import { CSSTransition } from 'react-transition-group'
import { Button } from '../button'

export interface DialogProps {
  afterClose?: () => any
  header?: ReactNode
  title?: ReactNode
  content?: ReactNode
  onClose?: () => any
  destroyOnClose?: boolean
  closeOnMaskClick?: boolean
  visible?: boolean
  getContainer?: HTMLElement | (() => HTMLElement) | null
  containerStyle?: React.CSSProperties
  containerClassName?: string
  bodyStyle?: React.CSSProperties
  bodyClassName?: string
  maskStyle?: React.CSSProperties
  maskClassName?: string
  cancelButtonText?: ReactNode
  confirmButtonText?: ReactNode
  hideCancelButton?: boolean
  hideConfirmButton?: boolean
  clickButtonClose?: boolean
  children?: ReactElement
  rightCloseIcon?: boolean
  onCancel?: () => void | Promise<void>
  onConfirm?: () => void | Promise<void>
  onCloseIcon?: () => void | Promise<void>
  unmount?: () => void
}
const unmounts: (() => void)[] = []
export const Dialog = (props: DialogProps) => {
  const [transform, setTransform] = useState('')
  const transformOrigin = (target: MouseEvent) => {
    setTransform(`${target.clientX}px ${target.clientY}px`)
  }
  useEffect(() => {
    props.unmount && unmounts.push(props.unmount)
    window.addEventListener('click', transformOrigin)
    return () => {
      window.removeEventListener('click', transformOrigin)
    }
  }, [])

  const onCancel = async () => {
    try {
      await props.onCancel?.()
      props.clickButtonClose && props.onClose?.()
    } catch {}
  }

  const onConfirm = async () => {
    try {
      await props.onConfirm?.()
      props.clickButtonClose && props.onClose?.()
    } catch {}
  }

  return (
    <div
      onClick={(event) => {
        event.preventDefault()
        event.stopPropagation()
      }}
    >
      <Mask
        visible={props.visible}
        destroyOnClose={props.destroyOnClose}
        disableBodyScroll
        getContainer={props.getContainer}
        afterClose={props.afterClose}
        style={props.maskStyle}
        className={classNames(styles.dialog, props.maskClassName)}
      />
      <CSSTransition
        in={props.visible}
        timeout={300}
        classNames={{
          enter: styles.dialogEnter,
          enterActive: styles.dialogEnterActive,
          exit: styles.dialogExit,
          exitActive: styles.dialogExitActive
        }}
        unmountOnExit={props.destroyOnClose}
      >
        <div className={styles.dialog} style={{ transformOrigin: transform }}>
          <div className={styles.dialogWrap}>
            {props.children || (
              <div
                style={props.containerStyle}
                onClick={(e) => e.stopPropagation()}
                className={classNames(styles.dialogContent, props.containerClassName)}
              >
                {props.header ??
                  (!!props.title && (
                    <div className={styles.dialogHeader}>
                      <div className={styles.dialogHeaderTitle}>{props.title}</div>
                    </div>
                  ))}

                <div
                  style={props.bodyStyle}
                  className={classNames(styles.dialogBody, props.bodyClassName)}
                >
                  {props.content}
                </div>
                <div className={classNames(styles.dialogFooter)}>
                  {!props?.hideCancelButton && (
                    <Button className={styles.dialogFooterButton} onClick={onCancel}>
                      {props.cancelButtonText}
                    </Button>
                  )}
                  {!props?.hideConfirmButton && (
                    <Button className={classNames(styles.dialogFooterButton)} onClick={onConfirm}>
                      {props.confirmButtonText}
                    </Button>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </CSSTransition>
    </div>
  )
}

export const clear = () => {
  while (true) {
    const unmount = unmounts.pop()
    if (!unmount) {
      break
    }
    unmount()
  }
}

Dialog.defaultProps = {
  cancelButtonText: '关闭',
  confirmButtonText: '确定',
  closeOnMaskClick: false,
  clickButtonClose: true,
  destroyOnClose: true
}
