import styles from './index.module.scss'
import MENU_ICON from '@/assets/imgs/icon_menu.png'
import classNames from 'classnames'

const Banner = () => {
  return (
    <div className={styles.banner}>
      <div className={styles.menu}>
        <div className={styles.menuSub}>
          <img className={styles.icon} src={MENU_ICON} />
          <div className={styles.text}>{'婚恋规划 基础知识>>'}</div>
        </div>
        <div className={styles.menuItem}>
          <div className={styles.line}>
            <a data-button>亲子关系</a>
            <a data-button>夫妻关系</a>
          </div>
          <div className={styles.line}>
            <a data-button>恋爱择偶</a>
            <a data-button>其他成员关系</a>
          </div>
        </div>
        <div className={styles.menuSub}>
          <img className={styles.icon} src={MENU_ICON} />
          <div className={styles.text}>{'婚恋规划 知识拓展>>'}</div>
        </div>
        <div className={styles.menuItem}>
          <div className={styles.line}>
            <a data-button>亲子教育</a>
            <a data-button>婚姻情感</a>
          </div>
          <div className={styles.line}>
            <a data-button>流派与技能应用</a>
          </div>
        </div>
        <div className={styles.menuSub}>
          <img className={styles.icon} src={MENU_ICON} />
          <div className={styles.text}>{'婚恋规划 热门问题>>'}</div>
        </div>
        <div className={styles.menuItem}>
          <div className={styles.line}>
            <a data-button>0基础可以考吗？</a>
          </div>
          <div className={styles.line}>
            <a data-button>考试难吗？</a>
          </div>
          <div className={styles.line}>
            <a data-button>近年来的政策变化？</a>
          </div>
          <div className={styles.line}>
            <a data-button>如何报考？有限制吗？</a>
          </div>
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.subtitle}>—报名考试服务中心—</div>
        <div className={styles.title}>2024年婚恋规划</div>
        <div className={styles.bread}>
          <span>需求量大/</span>
          <span>人才缺口大/</span>
          <span>前景广阔</span>
        </div>
        <div className={styles.desc}>***报考政策已公布***</div>
        <div className={styles.button} data-button>
          {'最新报考条件查询>>'}
        </div>
        <div className={classNames(styles.button, styles.red)} data-button>
          {'最新报考时间查询>>'}
        </div>
      </div>
    </div>
  )
}

export default Banner
