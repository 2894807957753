import styles from './index.module.scss'

const CONTENT = [
  { text: '婚姻律师事务所婚姻介绍所' },
  { text: '情感主播或情感博主' },
  { text: '婚恋栏目撰稿人辅导专家等' },
  {
    text: (
      <>
        独立咨询
        <br />
        自办婚恋规划
        <br />
        咨询中心
      </>
    )
  }
]
const ContentEight = () => {
  return (
    <div className={styles.content}>
      <div className={styles.title}>—— 婚恋规划的行业方向 ——</div>
      <div className={styles.list}>
        {CONTENT.map((each, index) => {
          return (
            <div key={index} className={styles.listItem}>
              <div className={styles.text}>{each.text}</div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default ContentEight
