import { Dialog } from '@/components/dialog'
import data from '@/assets/json/data.json'
import styles from './index.module.scss'
import { Button } from '@/components/button'
import { Fragment, useEffect, useState } from 'react'
import classnames from 'classnames'
import dayjs from 'dayjs'
import CLOSE_ICON from '@/assets/imgs/icon_close.png'
import useRefState from '../../hooks'
import axios from 'axios'
import SERVICE_ICON from '@/assets/imgs/service_icon.png'
import SELF_ICON from '@/assets/imgs/self_icon.png'
import STAR from '@/assets/imgs/star.png'
import SERVICE_BG from '@/assets/imgs/service_bg1.png'
import { toast } from '@/components/toast'

const sleep = (num: number) => {
  // eslint-disable-next-line no-promise-executor-return
  return new Promise((r) => setTimeout(r, num))
}
export const isPhone = (tel: unknown): boolean => {
  const valueAsString = String(tel)
  const reg = /^1[3-9]\d{9}$/
  return reg.test(valueAsString.replace(/\s/g, ''))
}
const TAGS = ['耐心解答', '沟通顺畅', '及时回复', '高度专业']
const COMMENTS = [
  {
    title: '王同学：',
    desc: '耐心、细致、有责任心、态度极好'
  },
  {
    title: '李同学：',
    desc: '老师服务特别好，很有耐心'
  },
  {
    title: '张同学：',
    desc: '这个咨询师很专业的 我很敬佩'
  },
  {
    title: '高同学：',
    desc: '解答问题逻辑清晰，体现出极高的专业度'
  },
  {
    title: '赵同学：',
    desc: '已顺利报名，期待后续服务'
  }
]
const ServiceModal = (props) => {
  const [visible, setVisible] = useState(false)
  const [value, setValue] = useState<any>({ username: '', phone: '' })
  const [loading, setLoading] = useRefState(false)
  useEffect(() => {
    setVisible(props.visible)
  }, [props.visible])
  const [session, setSession] = useRefState([])
  useEffect(() => {
    handleSelectOption({
      type: 'message',
      goto: data.root
    })
  }, [])
  // const containerEl = useRef(null)
  const handleSelectOption = async (option, parent?: any) => {
    if (parent && (parent?.type === 'select' || parent?.type === 'form')) {
      setSession((v) => {
        return v.map((each) => {
          if (each.name === parent.name) {
            return { ...each, click: true }
          }
          return each
        })
      })
    }
    if (option.type !== 'message') {
      setSession((v) => {
        return v.concat({
          time: dayjs().format('HH:mm:ss'),
          question: option.title,
          source: 'self'
        })
      })

      await sleep(800)
    }
    if (option.goto) {
      const current = { time: dayjs().format('HH:mm:ss'), ...data.nodes[option.goto] }
      setSession((v) => v.concat(current))
      await sleep(800)
      if (current.type === 'message') {
        handleSelectOption(current)
      }
    }
  }

  const handleSubmit = async (each) => {
    if (value.username.trim().length < 2) {
      toast.show('请输入正确的姓名')
      return
    }

    if (value.phone.trim().length < 11 || !isPhone(value.phone)) {
      toast.show('手机号格式不正确')
      return
    }

    setLoading(true)
    try {
      await axios.post(
        'https://api.aileyun.net/d/qywx/send?key=0f8d01d5-52bd-49b1-9aa9-1665e9207a8b',
        {
          msgtype: 'text',
          text: {
            content: `姓名：${value.username},手机号:${value.phone}`
          }
        }
      )
    } catch {
      toast.show('发送失败请重新发送')
    } finally {
      setLoading(false)
    }
    handleSelectOption(
      { goto: 'node_success1', title: `姓名:${value.username}\n手机号:${value.phone}` },
      each
    )
  }
  const containerEl = (e) => {
    e?.scrollTo?.(0, 999999)
  }
  return (
    <Dialog {...props} visible={visible} afterClose={() => props.onClose()}>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.subtitle}>婚恋规划证书报考</div>
          <div className={styles.close} onClick={() => setVisible(false)}>
            <img className={styles.icon} src={CLOSE_ICON} />
          </div>
        </div>

        <div className={styles.content}>
          <div className={styles.list} ref={containerEl}>
            {session.map((each, index) => {
              return (
                <div
                  key={index}
                  className={classnames(styles.listItem, {
                    [styles.flexRight]: each.source === 'self'
                  })}
                >
                  {!each.source && <img className={styles.img} src={SERVICE_ICON} />}
                  <div className={styles.box}>
                    <div className={classnames(styles.time, { [styles.textRight]: !!each.source })}>
                      <>{!each.source && '张老师 '}</>
                      {each.time}
                    </div>
                    <div className={styles.messageBox}>
                      <div className={each.fields && styles.messageBoxForm}>
                        {each.img && (
                          <img
                            src={SERVICE_BG}
                            style={{
                              borderRadius: 4,
                              width: '100%',
                              marginBottom: 8
                            }}
                          />
                        )}
                        {each.question && (
                          <div className={styles.messageBoxText}>{each.question}</div>
                        )}
                        {each.options && !!each.options.length && !each.click && (
                          <div className={styles.messageBoxOptions}>
                            {each.options.map((option, i) => {
                              return (
                                <Button
                                  key={i}
                                  className={styles.option}
                                  onClick={() => handleSelectOption(option, each)}
                                >
                                  {option.title}
                                </Button>
                              )
                            })}
                          </div>
                        )}

                        {each.fields && (
                          <>
                            {each.fields.map((field, index) => {
                              return (
                                <Fragment key={index}>
                                  <input
                                    // name={field.name}
                                    value={value[field.name]}
                                    readOnly={loading}
                                    maxLength={11}
                                    placeholder={`请输入${field.title}`}
                                    onChange={(e) => {
                                      setValue((v) => ({ ...v, [field.name]: e.target.value }))
                                    }}
                                  />
                                </Fragment>
                              )
                            })}
                            <Button
                              className={styles.submit}
                              loading={loading}
                              loadingText={'发送中...'}
                              onClick={() => handleSubmit(each)}
                            >
                              发送
                            </Button>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  {each.source === 'self' && (
                    <img className={classnames(styles.img, styles.imgRight)} src={SELF_ICON} />
                  )}
                </div>
              )
            })}
          </div>
          <div className={styles.rightPanel}>
            <div className={styles.rightPanelNav}>学员评价</div>
            <div className={styles.rightPanelBody}>
              <div className={styles.rightPanelInfo}>
                <div className={styles.headImg}>
                  <img src={SERVICE_ICON} />
                </div>
                <div className={styles.title}>张老师</div>
                <div className={styles.tag}>高级咨询顾问</div>
                <div className={styles.stars}>
                  {new Array(5).fill('').map((_, i) => {
                    return <img key={i} className={styles.starItem} src={STAR} />
                  })}
                </div>
                <div className={styles.statistic}>
                  <div className={styles.statisticItem}>
                    <div className={styles.number}>8年</div>
                    <div className={styles.text}>工作年限</div>
                  </div>
                  <div className={styles.statisticItem}>
                    <div className={styles.number}>16589</div>
                    <div className={styles.text}>服务学员人数</div>
                  </div>
                </div>
              </div>
              <div className={styles.rightPanelEvaluate}>
                <div className={styles.tags}>
                  {TAGS.map((item, i) => {
                    return (
                      <div key={i} className={styles.tagItem}>
                        {item}
                      </div>
                    )
                  })}
                </div>
                <div className={styles.comments}>
                  {COMMENTS.map((item, i) => {
                    return (
                      <div key={i} className={styles.commentItem}>
                        <div className={styles.title}>{item.title}</div>
                        <p className={styles.desc}>{item.desc}</p>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  )
}

// const model = withWrapper(ServiceModal, { destroyOnClose: false })
export default ServiceModal
