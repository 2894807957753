import styles from './index.module.scss'
import MAIN6_ICON1 from '@/assets/imgs/main6_icon1.png'
import MAIN6_ICON2 from '@/assets/imgs/main6_icon2.png'

import MAIN6_ICON3 from '@/assets/imgs/main6_icon3.png'
import MAIN6_ICON4 from '@/assets/imgs/main6_icon4.png'

const CONTENT_SIX = [
  {
    img: MAIN6_ICON1,
    title: '职业前景',
    text: '至少700万的单身及离异人群婚前需要情感服务，缺口巨大。'
  },
  {
    img: MAIN6_ICON2,
    title: '高收入',
    text: '市场客单价在10000元至22000元，甚至更高。'
  },
  {
    img: MAIN6_ICON3,
    title: '职业崇高',
    text: '可以终生从事，没有35岁职场危机，随着年龄和阅历增长而更受欢迎的职业'
  },
  {
    img: MAIN6_ICON4,
    title: '工作稳定',
    text: '目前还没有专门从事婚恋规划的专业人员。'
  }
]
const ContentSix = () => {
  return (
    <div className={styles.content}>
      <div className={styles.title}>—— 婚恋规划的四大必学理由 ——</div>
      <div className={styles.list}>
        {CONTENT_SIX.map((each, index) => {
          return (
            <div key={index} className={styles.listItem}>
              <img className={styles.img} src={each.img} />
              <div className={styles.listItemBody}>
                <div className={styles.top}>
                  <div className={styles.title}>{each.title}</div>
                  <div className={styles.text}>{each.text}</div>
                </div>

                <div className={styles.button} data-button>
                  {'了解更多>>'}
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default ContentSix
