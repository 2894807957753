import styles from './index.module.scss'
import MAIN7_ICON1 from '@/assets/imgs/main7_icon1.png'
import MAIN7_ICON2 from '@/assets/imgs/main7_icon2.png'

import MAIN7_ICON3 from '@/assets/imgs/main7_icon3.png'
import MAIN7_ICON4 from '@/assets/imgs/main7_icon4.png'
import MAIN7_ICON5 from '@/assets/imgs/main7_icon5.png'
import MAIN7_ICON6 from '@/assets/imgs/main7_icon6.png'

import MAIN7_ICON7 from '@/assets/imgs/main7_icon7.png'
import MAIN7_ICON8 from '@/assets/imgs/main7_icon8.png'

const CONTENT_SEVEN = [
  {
    img: MAIN7_ICON1,
    title: '学习无地点限制',
    text: (
      <>
        有网络就能上课
        <br />
        随时随地学习
      </>
    )
  },
  {
    img: MAIN7_ICON2,
    title: '零基础入学',
    text: (
      <>
        18岁以上，不限基础
        <br />
        零基础报考
      </>
    )
  },
  {
    img: MAIN7_ICON3,
    title: '费用性价比高',
    text: (
      <>
        高性价比，优质课程
        <br />
        优惠千元
      </>
    )
  },
  {
    img: MAIN7_ICON4,
    title: '学习方式灵活',
    text: (
      <>
        线上平台学习
        <br />
        想怎么学怎么学
      </>
    )
  },
  {
    img: MAIN7_ICON5,
    title: '简单好考',
    text: (
      <>
        只要按照课程安排走
        <br />
        考试通过没问题
      </>
    )
  },
  {
    img: MAIN7_ICON6,
    title: '证书含金量高',
    text: (
      <>
        证书有相关政策调整
        <br />
        社会认可度高
      </>
    )
  },
  {
    img: MAIN7_ICON7,
    title: '下证时间短',
    text: (
      <>
        最快一个月拿证
        <br />
        节约时间
      </>
    )
  },
  {
    img: MAIN7_ICON8,
    title: '投入时间少',
    text: (
      <>
        工作学习两不误
        <br />
        每天只需要1小时
      </>
    )
  }
]
const ContentSeven = () => {
  return (
    <div className={styles.content}>
      <div className={styles.title}>—— 省时、省力、省钱、省心的【报考服务】——</div>
      <div className={styles.list}>
        {CONTENT_SEVEN.map((each, index) => {
          return (
            <div key={index} className={styles.listItem}>
              <img className={styles.img} src={each.img} />
              <div className={styles.listItemBody}>
                <div className={styles.title}>{each.title}</div>
                <div className={styles.text}>{each.text}</div>
                <div className={styles.button} data-button>
                  {'了解更多>>'}
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default ContentSeven
