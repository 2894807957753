import classNames from 'classnames'
import { CSSProperties, memo } from 'react'

import { Ripple } from '../ripple'
import { IRippleWrapperProps } from '../ripple/RippleWrapper'
import styles from './index.module.scss'

export type IButtonProps = {
  className?: string
  style?: CSSProperties
  outlined?: boolean
  rounded?: boolean
  text?: boolean
  disabled?: boolean
  loading?: boolean
  loadingText?: string
  ripple?: Partial<IRippleWrapperProps>
  children?: React.ReactNode
  onClick?: (e: any) => any
}

const Button = memo((props: IButtonProps) => {
  const {
    className,
    style,
    ripple,
    loadingText,
    text,
    outlined,
    disabled,
    rounded,
    loading
  } = props

  return (
    <button
      style={style}
      className={classNames(styles.btn, className, {
        [styles.outlined]: outlined,
        [styles.rounded]: rounded,
        [styles.text]: text,
        [styles.disabled]: loading || disabled,
        [styles.loading]: loading
      })}
      disabled={loading || disabled}
      onClick={props?.onClick}
    >
      <>
        <Ripple {...(ripple ?? {})}>
          {loading ? (
            <div className={styles.loadingBlock}>
              {loadingText && <div className={styles.loadingText}>{loadingText}</div>}
            </div>
          ) : (
            props.children
          )}
        </Ripple>
      </>
    </button>
  )
})

export default Button
