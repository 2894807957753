import styles from './index.module.scss'
import IMG_LEFT from '@/assets/imgs/main4_left.png'
import IMG_RIGHT from '@/assets/imgs/main4_right.png'

const ContentFour = () => {
  return (
    <div className={styles.content}>
      <div className={styles.title}>—— 证书优势 ——</div>
      <div className={styles.main}>
        <div className={styles.imgBlock}>
          <div className={styles.subtitle}>官方授权单位</div>
          <img className={styles.imgLeft} src={IMG_LEFT} />
        </div>
        <div className={styles.imgBlock}>
          <div className={styles.subtitle}>证书样本</div>
          <img className={styles.imgRight} src={IMG_RIGHT} />
        </div>
        <div className={styles.textBlock}>
          <div className={styles.text}>人社部教育培训中心新设项目官方发文</div>
          <div className={styles.text}>证书官方认可，含金量高</div>
          <div className={styles.text}>官网可查询</div>
        </div>
      </div>
    </div>
  )
}

export default ContentFour
