import type { ReactElement } from 'react'
import { createRef, forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { createRoot } from 'react-dom/client'

import type { DialogProps } from './Dialog'
import { clear, Dialog } from './Dialog'

type Ref = {
  close: () => void
}
export function render(element: ReactElement) {
  if (typeof window !== 'undefined') {
    const container = document.createElement('div')
    document.body.appendChild(container)

    const root = createRoot(container)
    root.render(element)
    // eslint-disable-next-line no-inner-declarations
    function unmount() {
      root.unmount()
      document.body.removeChild(container)
    }
    return unmount
  }
}
export function show(props: Omit<DialogProps, 'visible'>) {
  const Wrapper = forwardRef<Ref>((_, ref) => {
    const [visible, setVisible] = useState(false)
    useEffect(() => {
      setVisible(true)
      window.addEventListener('popstate', unmount)
      return () => {
        window.addEventListener('popstate', unmount)
      }
    }, [])

    useImperativeHandle(ref, () => ({
      close: () => {
        setVisible(false)
      }
    }))

    return (
      <Dialog
        {...props}
        visible={visible}
        onClose={() => {
          props.onClose?.()
          setVisible(false)
        }}
        onCloseIcon={() => {
          props?.onCloseIcon?.()
          setVisible(false)
        }}
        unmount={() => unmount?.()}
        afterClose={() => {
          props.afterClose?.()
          unmount?.()
        }}
      />
    )
  })
  const ref = createRef<Ref>()
  const unmount = render(<Wrapper ref={ref} />)
  return {
    close: ref.current?.close
  }
}

const dialog = { show, clear }
export default dialog
