import styles from './index.module.scss'
import NUMBER1 from '@/assets/imgs/1.png'
import NUMBER2 from '@/assets/imgs/2.png'

import NUMBER3 from '@/assets/imgs/3.png'
import NUMBER4 from '@/assets/imgs/4.png'
import NUMBER5 from '@/assets/imgs/5.png'

import ARROW from '@/assets/imgs/arrow_icon.png'

const CONTENT = [
  {
    img: NUMBER1,
    title: '线上报名',
    text: (
      <>
        缴费后由授权培
        <br />
        训机构提交报名
      </>
    )
  },
  {
    img: NUMBER2,
    title: '线上学习',
    text: (
      <>
        录播：
        <br />
        1、线上平台进行录播课学习；
        <br />
        2、培训课程通过讲座和在线演练以及课后交流群互动演练形式，保证学员学习时长有效性和趣味性
      </>
    )
  },
  {
    img: NUMBER3,
    title: '线上考试',
    text: (
      <>
        考试考核：
        <br />
        1、完成所有培训课程后方能报名参加考试；
        <br />
        2、通过在线考试平台进行线上考试；
        <br />
        3、培训全过程留痕管理，保留学员个人档案、学习记录和考试成绩等资料
      </>
    )
  },
  {
    img: NUMBER4,
    title: '考试通过',
    text: <>考试后7个工作日出考试成绩</>
  },
  {
    img: NUMBER5,
    title: '颁发证书',
    text: <>考试通过后可在人社局官网查询证书信息</>
  }
]
const ContentEleven = () => {
  return (
    <div className={styles.content}>
      <div className={styles.title}>—— 培训流程 ——</div>
      <div className={styles.list}>
        {CONTENT.map((each, index) => {
          return (
            <div key={index} className={styles.listItem}>
              <div className={styles.listItemHeader}>
                <img className={styles.number} src={each.img} />
                {
                  <img
                    className={styles.arrow}
                    src={ARROW}
                    style={{ visibility: index < CONTENT.length - 1 ? 'visible' : 'hidden' }}
                  />
                }
              </div>
              <div className={styles.listItemSubtitle}>{each.title}</div>

              <div className={styles.listItemText}>{each.text}</div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default ContentEleven
