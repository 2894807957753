import type { FC } from 'react';

export function withDefaultProps<D>(defaultProps: D) {
	return function <P>(e: FC<P & typeof defaultProps>) {
		e.defaultProps = defaultProps;
		return e as FC<P>;
	};
}

export function mergeProps<P, D>(defaultProps: D, props: P): P & D {
	return { ...defaultProps, ...props };
}
