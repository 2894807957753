import Banner from './components/Banner'
import styles from './index.module.scss'

import ContentOne from './components/ContentOne'
import ContentThree from './components/ContentThree'
import ContentFour from './components/ContentFour'
import ContentSeven from './components/ContentSeven'
import ContentSix from './components/ContentSix'
import ContentFive from './components/ContentFive'
import ContentEight from './components/ContentEight'
import ContentSecond from './components/ContentSecond'
// import ContentNine from './components/ContentNine'
import { useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import ServiceModal from './components/ServiceModal'
import ContentTen from './components/ContentTen'
import useRefState from './hooks'
import FLOAT_IMG_TOP from '@/assets/imgs/career_shake_topx.png'
import FLOAT_IMG_LEFT from '@/assets/imgs/career_shake_left.png'
import FLOAT_IMG_RIGHT from '@/assets/imgs/career_right_bg.png'

import ContentEleven from './components/ContentEleven'

const HomePage = () => {
  const [load, setLoad] = useRefState(false)
  const [visible, setVisible] = useState(false)
  const timer = useRef<NodeJS.Timeout>()
  useEffect(() => {
    window.addEventListener('click', (e) => {
      if ((e.target as HTMLDivElement).getAttribute('data-button')) {
        setLoad(true)
        setVisible(true)
      }
    })
  }, [])

  useEffect(() => {
    timer.current = setTimeout(() => {
      if (!load && !visible) {
        setLoad(true)
        setVisible(true)
      }
    }, 3000)

    return () => {
      timer.current && (timer.current = null)
    }
  }, [load, visible])
  return (
    <>
      <Banner />
      <ContentOne />
      <ContentSecond />
      <ContentThree />
      <ContentFour />
      <ContentFive />
      <ContentSix />
      <ContentTen />
      <ContentEleven />
      <ContentSeven />
      <ContentEight />
      {/* <ContentNine /> */}
      <div className={styles.content}>
        <div className={styles.title}>从“学习-考试-就业”全面服务</div>
        <div className={styles.text}>
          学习婚恋规划，不仅能够帮助自己获得更美满家庭生活， 同时也是获得更好的就业机会
        </div>
        <div className={classNames(styles.button)} data-button>
          即刻学习课程
        </div>
      </div>
      <div
        className={styles.floatButton}
        onClick={() => {
          setLoad(true)
          setVisible(true)
        }}
      >
        <img src={FLOAT_IMG_LEFT} className={styles.shakeLeft} />
        <img src={FLOAT_IMG_TOP} className={styles.shake} />
        <img src={FLOAT_IMG_RIGHT} />
      </div>
      <img />
      <ServiceModal visible={visible} onClose={() => setVisible(false)} />
    </>
  )
}

export default HomePage
