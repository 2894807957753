import styles from './index.module.scss'
import HEAD_IMAG1 from '@/assets/imgs/head_img1.png'
import HEAD_IMAG2 from '@/assets/imgs/head_img2.png'
import HEAD_IMAG3 from '@/assets/imgs/head_img3.png'
import HEAD_IMAG4 from '@/assets/imgs/head_img4.png'
import HEAD_IMAG5 from '@/assets/imgs/head_img5.png'

const TEACHERS = [
  {
    img: HEAD_IMAG1,
    name: '潘翊',
    prof: '特级教师',
    desc: [
      '毕业于上海财经大学、获澳洲南格斯大学MBA',
      'TCL首席教练型培训师',
      '萨提亚模式治疗师',
      '国际ICF认证NLP PCC教练',
      'LMI(美国领导管理发展中心)领导力发展教练'
    ]
  },
  {
    img: HEAD_IMAG2,
    name: '廖皓璇',
    prof: '特级教师',
    desc: [
      '( ICF ) 认证 PCC教练',
      '资深家庭关系导师教练',
      '职业发展教练',
      '资深TTT教练型导师',
      '研习心理学超过十年，ACC/PCC教练督导老师'
    ]
  },
  {
    img: HEAD_IMAG3,
    name: '谷晓燕',
    prof: '特级教师',
    desc: [
      '清华大学老师',
      '清华大学心理咨询中心咨询师',
      '全球教练联盟会员',
      '职业生涯教练、领导力教练',
      '美国CCE认证全球职业规划师(GCDF)',
      '国家生涯规划师（CMT）'
    ]
  },

  {
    img: HEAD_IMAG4,
    name: '谷晓燕',
    prof: '特级教师',
    desc: [
      '毕业于上海财经大学、获澳洲南格斯大学MBA',
      'TCL首席教练型培训师',
      '萨提亚模式治疗师',
      '国际ICF认证NLP PCC教练',
      'LMI(美国领导管理发展中心)领导力发展教练'
    ]
  },
  {
    img: HEAD_IMAG5,
    name: '杨东峰',
    prof: '特级教师',
    desc: [
      '国际EAP协会中国分会认证“国际EAP专员”',
      '国家二级心理咨询师',
      '贵港卫健委专家库成员',
      '贵港安澜精神病医院常务副院长'
    ]
  }
]

const ContentThree = () => {
  return (
    <div className={styles.content}>
      <div className={styles.title}>——12年社会家庭专业领域团队 ——</div>
      <div className={styles.list}>
        {TEACHERS.map((each, index) => {
          return (
            <div key={index} className={styles.listItem}>
              <img className={styles.img} src={each.img} />
              <div className={styles.title}>
                <div className={styles.name}>{each.name}</div>
                <div className={styles.desc}>{each.prof}</div>
              </div>
              <div className={styles.main}>
                {each.desc.map((item, i) => {
                  return (
                    <div key={i} className={styles.text}>
                      {item}
                    </div>
                  )
                })}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default ContentThree
