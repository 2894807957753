import { useEffect, useState } from 'react'
import { createRoot, Root } from 'react-dom/client'

import { resolveContainer } from '../utils/get-container'
import { inBrowser } from '../utils/in-browser'
import { mergeProps } from '../utils/with-default-props'
import type { ToastProps } from './Toast'
import InternalToast from './Toast'

const containers = [] as Element[]
const renders = [] as Root[]
let bodyContainer: HTMLElement | null = null
export type ToastShowProps = Omit<ToastProps, 'visible'>

const defaultProps = {
  duration: 2000
}

function show(p: ToastShowProps | string) {
  if (inBrowser) {
    const props = mergeProps(defaultProps, typeof p === 'string' ? { content: p } : p)
    let timer = 0
    const { getContainer = () => document.body } = props
    const container = document.createElement('div')
    bodyContainer = resolveContainer(getContainer)!
    bodyContainer.appendChild(container)
    clear()

    const TempToast = () => {
      const [visible, setVisible] = useState(false)

      useEffect(() => {
        setVisible(true)
      }, [])

      useEffect(() => {
        if (props.duration === 0) {
          return
        }
        timer = window.setTimeout(() => {
          setVisible(false)
        }, props.duration)
        return () => {
          window.clearTimeout(timer)
        }
      }, [])

      return (
        <InternalToast
          {...props}
          getContainer={() => container}
          visible={visible}
          afterClose={() => {
            c.unmount()
            props?.afterClose?.()
          }}
        />
      )
    }
    const c = createRoot(container)

    c.render(<TempToast />)
    renders.push(c)
    containers.push(container)
  }
}

function clear() {
  // eslint-disable-next-line no-constant-condition
  while (true) {
    const container = containers.pop()
    const render = renders.pop()
    if (!container || !render) {
      break
    }
    render.unmount()
    bodyContainer!.removeChild(container)
  }
}
const toast = { show, clear }
export default toast
