import { Button } from '@/components/button'
import styles from './index.module.scss'
import PDF_ICON from '@/assets/imgs/pdf_icon.png'

const LIST1 = [
  {
    title: '2024年婚恋规划职业考试大纲',
    read: 12081
  },
  {
    title: '恋爱择偶观与两性心理特点分析',
    read: 6912
  },
  {
    title: '陷入失恋消极情绪指导指南',
    read: 17834
  },
  {
    title: '内向性格搭讪异性问题解决方案',
    read: 22487
  }
]

const LIST2 = [
  {
    title: '婚恋规划职业考试报考条件与注意事项',
    read: 23482
  },
  {
    title: '婚姻生活中的各项权益保护指南',
    read: 9823
  },
  {
    title: '异地恋相处技巧与注意事项',
    read: 19892
  },
  {
    title: '情侣感情升温小技巧',
    read: 11235
  }
]

const ContentSecond = () => {
  return (
    <div className={styles.content}>
      <div className={styles.contentTitle}>—— 2024考试资料在线领取 ——</div>
      <div className={styles.main}>
        <div className={styles.list}>
          {LIST1.map((each, index) => {
            return (
              <div key={index} className={styles.listItem}>
                <div className={styles.title}>{each.title}</div>
                <div className={styles.info}>
                  <img className={styles.img} src={PDF_ICON} />
                  <div className={styles.right}>
                    <div className={styles.text}>{each.read}人阅读</div>
                    <Button className={styles.btn} data-button>
                      在线领取
                    </Button>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
        <div className={styles.list}>
          {LIST2.map((each, index) => {
            return (
              <div key={index} className={styles.listItem}>
                <div className={styles.title}>{each.title}</div>
                <div className={styles.info}>
                  <img className={styles.img} src={PDF_ICON} />
                  <div className={styles.right}>
                    <div className={styles.text}>{each.read}人阅读</div>
                    <Button className={styles.btn} data-button>
                      在线领取
                    </Button>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
        <Button className={styles.button} data-button>
          2024最新最全面资料领取
        </Button>
      </div>
    </div>
  )
}
export default ContentSecond
